import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import CheckoutView from '../components/Checkout'
import Layout from '../components/Global/Layout'
import Loader from '../components/Global/Loader'
import { GlobalStateContext } from '../context/GlobalContextProvider'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY)

export const query = graphql`
  {
    allContentfulCheckout {
      edges {
        node {
          steps {
            internal {
              content
            }
          }
          stepOutlineTitle
          stepsOutline {
            internal {
              content
            }
          }
          stepsInfo
          pricingConfigurator {
            internal {
              content
            }
          }
          countryOptions {
            internal {
              content
            }
          }
          serviceLevels {
            internal {
              content
            }
          }
          applicantInformation {
            internal {
              content
            }
          }
          trademarkInformation {
            internal {
              content
            }
          }
          review {
            internal {
              content
            }
          }
          checkout {
            internal {
              content
            }
          }
          filingDetails {
            internal {
              content
            }
          }
          seoContent {
            internal {
              content
            }
          }
          node_locale
        }
      }
    }

    allContentfulPricingPage {
      edges {
        node {
          title
          bodyText0
          heading0
          stepHeading1
          bodyText1
          stepsOutline {
            internal {
              content
            }
          }
          bodyText2
          pricingConfigurator {
            internal {
              content
            }
          }
          stepHeading2
          bodyText3
          countryOptions
          stepHeading3
          bodyText4
          serviceLevels {
            internal {
              content
            }
          }
          cart {
            internal {
              content
            }
          }
          node_locale
        }
      }
    }

    allContentfulOwnrCardCheckout {
      edges {
        node {
          button
          buttonLink
          description
          title
          subTitle
          node_locale
        }
      }
    }

    amx_logo: file(relativePath: { eq: "checkout_page/amx_logo.png" }) {
      childImageSharp {
        fixed(width: 72, height: 72) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    amx_logo_sm: file(relativePath: { eq: "checkout_page/amx_logo.png" }) {
      childImageSharp {
        fixed(width: 32, height: 32) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    discover_logo: file(
      relativePath: { eq: "checkout_page/discover_logo.png" }
    ) {
      childImageSharp {
        fixed(width: 49, height: 32) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    discover_logo_sm: file(
      relativePath: { eq: "checkout_page/discover_logo.png" }
    ) {
      childImageSharp {
        fixed(width: 22, height: 14) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    master_card_logo: file(
      relativePath: { eq: "checkout_page/master_card_logo.png" }
    ) {
      childImageSharp {
        fixed(width: 152, height: 35) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    master_card_logo_sm: file(
      relativePath: { eq: "checkout_page/master_card_logo.png" }
    ) {
      childImageSharp {
        fixed(width: 69, height: 15) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    visa_logo: file(relativePath: { eq: "checkout_page/visa_logo.png" }) {
      childImageSharp {
        fixed(width: 55, height: 31) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    visa_logo_sm: file(relativePath: { eq: "checkout_page/visa_logo.png" }) {
      childImageSharp {
        fixed(width: 25, height: 14) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    ca_flag: file(relativePath: { eq: "pricing_page/ca_flag.png" }) {
      childImageSharp {
        fixed(width: 45, height: 25) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    us_flag: file(relativePath: { eq: "pricing_page/us_flag.png" }) {
      childImageSharp {
        fixed(width: 45, height: 25) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ca_us_flag: file(relativePath: { eq: "pricing_page/ca_us_flag.png" }) {
      childImageSharp {
        fixed(width: 75, height: 20) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    allCountryStates {
      edges {
        node {
          internal {
            content
          }
        }
      }
    }
  }
`

const Checkout = (props) => {
  const {
    allContentfulCheckout,
    allContentfulOwnrCardCheckout,
    allContentfulPricingPage,
    ca_flag,
    us_flag,
    ca_us_flag,
    allCountryStates
  } = props.data
  const imgArray = {
    amx_logo: [
      props.data?.amx_logo.childImageSharp.fixed,
      {
        ...props.data?.amx_logo_sm.childImageSharp.fixed,
        media: `(max-width: 768px)`
      }
    ],
    discover_logo: [
      props.data?.discover_logo.childImageSharp.fixed,
      {
        ...props.data?.discover_logo_sm.childImageSharp.fixed,
        media: `(max-width: 768px)`
      }
    ],
    master_card_logo: [
      props.data?.master_card_logo.childImageSharp.fixed,
      {
        ...props.data?.master_card_logo_sm.childImageSharp.fixed,
        media: `(max-width: 768px)`
      }
    ],
    visa_logo: [
      props.data?.visa_logo.childImageSharp.fixed,
      {
        ...props.data?.visa_logo_sm.childImageSharp.fixed,
        media: `(max-width: 768px)`
      }
    ]
  }

  const state = useContext(GlobalStateContext)

  const ownrCardData = allContentfulOwnrCardCheckout?.edges.filter(
    (node) => node.node.node_locale === state.locale
  )

  const data = allContentfulCheckout?.edges.filter(
    (node) => node.node.node_locale === state.locale
  )[0].node

  const pageData = allContentfulPricingPage?.edges.filter(
    (node) => node.node.node_locale === state.locale
  )[0].node

  var checkoutSEO = null
  try {
    checkoutSEO = JSON.parse(data.seoContent.internal.content)
  } catch (error) {
    checkoutSEO = null
  }

  const countriesStates = JSON.parse(
    allCountryStates.edges[0]?.node?.internal?.content
  )

  if (!state.locale) {
    return (
      <Layout url='/process' locale={state.locale}>
        <Loader loading='true' />
      </Layout>
    )
  }

  return (
    <Layout
      locale={state.locale}
      url='/checkout'
      {...(checkoutSEO
        ? {
          title: checkoutSEO.title,
          desc: checkoutSEO.desc
        }
        : '')}
    >
      <Elements stripe={stripePromise}>
        <CheckoutView
          locale={state.locale}
          checkoutFormImages={imgArray}
          ownrCardData={ownrCardData}
          pageData={pageData}
          data={data}
          countriesStates={countriesStates}
          images={{ Canada: ca_flag, 'U.S.A': us_flag, Both: ca_us_flag }}
        />
      </Elements>
    </Layout>
  )
}

export default Checkout
